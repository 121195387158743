import request from '@/utils/request'

// 预约染发列表
export const reservationListAPI = (params) => {
  return request({
    url: '/reservationList',
    method: 'GET',
    params
  })
}

// 导出
export const reportReservationAPI = (params) => {
  return request({
    url: '/reportReservation',
    method: 'GET',
    params
  })
}
