<template>
  <div>
    <el-form :model="search">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="请输入员工姓名">
            <el-input v-model="search.staff_name" placeholder="请输入员工姓名" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入员工手机号">
            <el-input v-model="search.staff_phone" placeholder="请输入员工手机号" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="请输入顾客昵称">
            <el-input v-model="search.custom_nickname" placeholder="请输入顾客昵称" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入顾客手机号">
            <el-input v-model="search.custom_phone" placeholder="请输入顾客手机号" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择预约状态">
            <el-select placeholder="请选择预约状态" v-model="search.status">
              <el-option label="待认领" :value="0" />
              <el-option label="已认领" :value="1" />
              <el-option label="已取消" :value="2" />
              <el-option label="已完成" :value="3" />
              <el-option label="超时未到店" :value="4" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-button type="primary" @click="onSearchBtn" :icon="Search"
              >搜索</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <el-button
            :loading="loading"
            type="success"
            @click="onPayTheFeesexport"
          >
            导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->

    <el-table
      :data="listData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
     <el-table-column prop="id" label="id" width="80px" />
     <el-table-column prop="custom_nickname" label="顾客昵称" width="100px" />
     <el-table-column prop="custom_phone" label="顾客手机号" width="120px" />
      <el-table-column prop="staff_name" label="员工姓名" width="100px" />
      <el-table-column prop="staff_phone" label="员工手机号" width="120px" />
      <el-table-column prop="yuyue_time" label="预约时间" width="180px" />
      <el-table-column prop="address" label="地址" width="350px" />
      <el-table-column label="预约状态" width="100">
       <!-- <template v-slot="{ row }">
          {{ row.status ? '已冻结' : '正常' }}
        </template> -->
        <template v-slot="{ row }">
          {{row.status==0?"待认领" : row.status==1?"已认领" : row.status==2?"已取消" : row.status==3?"已完成" :"超时未到店"}}
        </template>
      </el-table-column>
      <el-table-column prop="add_time" label="添加时间" width="150px" />
      <el-table-column prop="update_time" label="更新时间" width="150px" />
      <el-table-column prop="demo" label="备注" width="300px" />

    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
// articleListAPI
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { reservationListAPI, reportReservationAPI } from '@/api/reservation'

/**
 *  表单 S
 */
// 表单数据源
const search = ref({
  class_id: '',
  title: '',
  page: 1,
  limit: 10,
  total: 0
})
// 搜索
const onSearchBtn = () => {
  onListData()
}

/**
 *  表单 E
 */

/*
 *  表格： S
 */

// 列表
const listData = ref([])
const onListData = async () => {
  const { data, total } = await reservationListAPI(search.value)
  search.value.total = total
  listData.value = data
}
onListData()

/** 导出 E */
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await reportReservationAPI(search.value)
  window.location.href = `https://zct.yslts.com${data}`
  loading.value = false
}

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListData()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListData()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
